import React, {useEffect, useReducer} from 'react';
import {NavigateFunction, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {client} from "../blinkDebitClientInstance";
import {
    Amount,
    AmountCurrencyEnum,
    Consent,
    ConsentStatusEnum,
    PaymentRequest,
    PaymentResponse,
    Pcr
} from "blink-debit-api-client-node";

const initialState = {
    paymentId: null as string | null,
    apiError: null as string | null,
    isLoading: true,
};

type State = typeof initialState;

type Action =
        | { type: 'SET_PAYMENT_ID'; payload: string }
        | { type: 'SET_API_ERROR'; payload: string | null }
        | { type: 'SET_LOADING'; payload: boolean };

const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'SET_PAYMENT_ID':
            return {...state, paymentId: action.payload, apiError: null};
        case 'SET_API_ERROR':
            return {...state, apiError: action.payload, isLoading: false};
        case 'SET_LOADING':
            return {...state, isLoading: action.payload};
        default:
            return state;
    }
};

const parseSearchParams = (search: string, key: string): string | null => {
    return new URLSearchParams(search).get(key);
};

const Container = () => {
    const navigate: NavigateFunction = useNavigate();
    const search: string = useLocation().search;
    const error = parseSearchParams(search, "error");
    const consentId = parseSearchParams(search, "cid");
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        const fetchData = async () => {
            if (!error && consentId) {
                try {
                    dispatch({type: 'SET_LOADING', payload: true});
                    const enduringConsent: Consent = await client.getEnduringConsent(consentId);

                    if (enduringConsent.status === ConsentStatusEnum.Authorised) {
                        const amount = parseFloat(process.env.REACT_APP_BLINKPAY_AMOUNT || "0.04") / 4;
                        const total = amount.toFixed(2);

                        const paymentRequest: PaymentRequest = {
                            consentId,
                            amount: {currency: AmountCurrencyEnum.NZD, total} as Amount,
                            pcr: {code: "code", particulars: "lollipop", reference: "1 of 4"} as Pcr
                        };

                        const paymentResponse: PaymentResponse = await client.createPayment(paymentRequest);
                        if (paymentResponse.paymentId) {
                            dispatch({type: 'SET_PAYMENT_ID', payload: paymentResponse.paymentId});
                        }
                    }
                } catch (error: any) {
                    dispatch({type: 'SET_API_ERROR', payload: error.message || "An unknown error occurred"});
                } finally {
                    dispatch({type: 'SET_LOADING', payload: false});
                }
            } else {
                dispatch({type: 'SET_API_ERROR', payload: error});
                dispatch({type: 'SET_LOADING', payload: false});
            }
        };

        fetchData();
    }, [error, consentId]);

    useEffect(() => {
        if (!consentId) navigate('/');
    }, [consentId, navigate]);

    const {isLoading, paymentId, apiError} = state;

    if (isLoading) {
        return <div className="container">Loading...</div>;
    }

    return (
            <div className="container">
                {apiError == null ? (
                        <div className="alert alert-success d-flex align-items-center" role="alert">
                            <div className="col-1 d-flex justify-content-center align-items-center">
                                <i className="bi bi-check-circle-fill"></i>
                            </div>
                            <div className="col text-left">
                                <h3 className="alert-heading">Success</h3>
                                <p>Payment successful for consent ID: {consentId}</p>
                                <p>Payment ID: {paymentId}</p>
                            </div>
                        </div>
                ) : (
                        <div className="alert alert-danger d-flex align-items-center" role="alert">
                            <div className="col-1 d-flex justify-content-center align-items-center">
                                <i className="bi bi-exclamation-circle-fill"></i>
                            </div>
                            <div className="col text-left">
                                <h3 className="alert-heading">Error</h3>
                                <p>Payment unsuccessful for consent ID: {consentId}</p>
                                <p>{apiError}</p>
                            </div>
                        </div>
                )}
                <button className="btn btn-primary mt-3" onClick={() => navigate('/')}>Back to Shopping Cart</button>
            </div>
    );
};

export default function Pay() {
    return (
            <Routes>
                <Route path="/" element={<Container/>}/>
            </Routes>
    );
}
