import React, {Component} from 'react';
import lollipop from '../lolly.webp';
import {
    Amount,
    AmountCurrencyEnum,
    AuthFlow,
    AuthFlowDetailTypeEnum,
    ConsentDetailTypeEnum,
    EnduringConsentRequest,
    GatewayFlow,
    Pcr,
    Period,
    QuickPaymentRequest,
    SingleConsentRequest
} from 'blink-debit-api-client-node';
import {client} from '../blinkDebitClientInstance';
import {AppRedirectFlow} from "../AppRedirectFlow";

interface State {
    errorResponse: any,
    disabled: boolean,
    clickedButton?: string,
    imageLoaded: boolean,
    showPopup: boolean,
    quantity: number,
    unitPrice: number
}

class Cart extends Component<{}, State> {
    constructor(props: {}) {
        super(props);

        const unitPrice = parseFloat(process.env.REACT_APP_BLINKPAY_AMOUNT || '0.01');

        this.state = {
            errorResponse: {},
            disabled: false,
            imageLoaded: false,
            showPopup: false,
            quantity: 1,
            unitPrice
        };

        this.submitForm = this.submitForm.bind(this);
    }

    generateRandomString(length: number): string {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            result += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return result;
    }

    handleButtonClick = (value: string, e: React.MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();

        if (process.env.REACT_APP_ENV === 'prod') {
            this.setState({showPopup: true, clickedButton: value}, () => {
                this.submitForm(e as unknown as React.FormEvent);
            });
            return;
        }

        this.setState({disabled: true, clickedButton: value}, () => {
            this.submitForm(e as unknown as React.FormEvent);
        });
    };

    handlePopupConfirm = (): void => {
        this.setState({showPopup: false, disabled: true}, () => {
            this.submitForm(new Event('submit') as unknown as React.FormEvent);
        });
    };

    handlePopupCancel = (): void => {
        this.setState({showPopup: false, disabled: false, clickedButton: undefined});
    };

    handleImageLoad = (): void => {
        this.setState({imageLoaded: true});
    };

    handleQuantityChange = (change: number) => {
        this.setState((prevState) => ({
            quantity: Math.max(1, prevState.quantity + change)
        }));
    };

    handleQuantityInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(e.target.value, 10);
        if (!isNaN(value) && value > 0) {
            this.setState({quantity: value});
        }
    };

    calculateTotal = (): number => {
        const {unitPrice, quantity} = this.state;
        const subtotal = unitPrice * quantity;
        const fee = Math.min(subtotal * 0.0095, 3);
        return subtotal + fee;
    };

    async submitForm(e: React.FormEvent): Promise<void> {
        e.preventDefault();

        const buttonValue: string | undefined = this.state.clickedButton;
        const randomCode = this.generateRandomString(4);
        const totalAmount = this.calculateTotal().toFixed(2);

        this.setState({disabled: true, errorResponse: {}});

        try {
            let request;
            let response;
            if (buttonValue === "single") {
                request = {
                    type: ConsentDetailTypeEnum.Single,
                    flow: {
                        detail: {
                            type: AuthFlowDetailTypeEnum.Gateway,
                            redirectUri: `${window.location.origin}/redirect`
                        } as GatewayFlow
                    } as AuthFlow,
                    amount: {currency: AmountCurrencyEnum.NZD, total: totalAmount} as Amount,
                    pcr: {particulars: "lollipop", code: `code-${randomCode}`, reference: "reference"} as Pcr
                };
                response = await client.createQuickPayment(request as QuickPaymentRequest);
            } else if (buttonValue === "enduring") {
                request = {
                    type: ConsentDetailTypeEnum.Enduring,
                    flow: {
                        detail: {
                            type: AuthFlowDetailTypeEnum.Gateway,
                            redirectUri: `${window.location.origin}/pay`
                        } as GatewayFlow
                    } as AuthFlow,
                    maximumAmountPeriod: {currency: AmountCurrencyEnum.NZD, total: totalAmount} as Amount,
                    fromTimestamp: new Date(),
                    period: Period.Fortnightly
                };
                response = await client.createEnduringConsent(request as EnduringConsentRequest);
            } else if (buttonValue === "asb") {
                request = {
                    type: ConsentDetailTypeEnum.Single,
                    flow: {
                        detail: {
                            bank: 'ASB',
                            type: AuthFlowDetailTypeEnum.Redirect,
                            redirectUri: 'blinkpay://test-app/return',
                            redirectToApp: true
                        } as AppRedirectFlow
                    } as AuthFlow,
                    amount: {currency: AmountCurrencyEnum.NZD, total: totalAmount} as Amount,
                    pcr: {particulars: "lollipop", code: `code-${randomCode}`, reference: "reference"} as Pcr
                };
                response = await client.createQuickPayment(request as QuickPaymentRequest);
            } else if (buttonValue === "xero") {
                request = {
                    type: ConsentDetailTypeEnum.Single,
                    flow: {
                        detail: {
                            type: AuthFlowDetailTypeEnum.Gateway,
                            redirectUri: `${window.location.origin}/redirect`
                        } as GatewayFlow
                    } as AuthFlow,
                    amount: {currency: AmountCurrencyEnum.NZD, total: totalAmount} as Amount,
                    pcr: {particulars: "lollipop", code: `code-${randomCode}`, reference: "reference"} as Pcr
                };
                response = await client.createSingleConsent(request as SingleConsentRequest);
            }

            if (response?.redirectUri) {
                window.location.assign(response.redirectUri);
            }
        } catch (error) {
            this.setState({errorResponse: error, disabled: false});
        }
    }

    render() {
        const {imageLoaded, quantity, unitPrice, showPopup} = this.state;
        const total = this.calculateTotal();

        return (
                <div className="container">
                    <h3 className="text-center">Shopping Cart</h3>
                    <form onSubmit={this.submitForm} noValidate>
                        <div className="row align-items-center justify-content-center product-card">
                            <div className="col-md-3 col-sm-12 mb-3">
                                {!imageLoaded && (
                                        <div className="d-flex justify-content-center align-items-center"
                                             style={{height: 200}}>
                                            <div className="spinner-border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                )}
                                <img src={lollipop as string} alt="lollipop" className="img-fluid"
                                     onLoad={this.handleImageLoad}/>
                            </div>

                            <div className="col-md-3 col-sm-12 mb-3">
                                <p className="mb-1 fw-bold">Red Heart Lollipop, unwrapped</p>
                                <p className="mb-1">${unitPrice.toFixed(2)} each</p>
                            </div>

                            <div className="col-md-3 col-sm-12 mb-3">
                                <div className="quantity-controls d-flex align-items-center">
                                    <input
                                            type="text"
                                            className="form-control mx-2"
                                            style={{width: '60px', textAlign: 'center'}}
                                            value={quantity}
                                            onChange={this.handleQuantityInputChange}
                                            min="1"
                                    />
                                    <button className="btn btn-secondary" type="button"
                                            onClick={() => this.handleQuantityChange(-1)}>-
                                    </button>
                                    <button className="btn btn-secondary" type="button"
                                            onClick={() => this.handleQuantityChange(1)}>+
                                    </button>
                                </div>
                            </div>

                            <div className="col-md-3 col-sm-12 mb-3">
                                <p className="mb-1 fs-5">${total.toFixed(2)}</p>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-center mt-4">
                            <div className="col-12 text-center">
                                <button className="btn btn-primary m-2"
                                        onClick={(e) => this.handleButtonClick('single', e)}
                                        disabled={this.state.disabled}>PayNow
                                </button>
                                <button className="btn btn-primary m-2"
                                        onClick={(e) => this.handleButtonClick('enduring', e)}
                                        disabled={this.state.disabled}>AutoPay
                                </button>
                                <button className="btn btn-primary m-2"
                                        onClick={(e) => this.handleButtonClick('xero', e)}
                                        disabled={this.state.disabled}>Invoice
                                </button>
                            </div>
                        </div>
                    </form>

                    {showPopup && (
                            <div className="popup-overlay">
                                <div className="popup">
                                    <button className="popup-close" onClick={this.handlePopupCancel}>&times;</button>
                                    <h4>Confirm Payment</h4>
                                    <p>If accepted, this will constitute a real charge on your bank account.</p>
                                    <div className="popup-actions">
                                        <button className="btn btn-success" onClick={this.handlePopupConfirm}>Confirm
                                        </button>
                                        <button className="btn btn-danger" onClick={this.handlePopupCancel}>Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                    )}
                </div>
        );
    }
}

export default Cart;
